const navLinks = [
	{ title: `Početna`, path: `/` },
	{ title: `Lokacija`, path: `/lokacija` },
	{ title: `Stanovi`, path: `/stanovi` },
	{ title: `Sadržaj`, path: `/sadrzaj` },
	{ title: `Projekti`, path: `/projekti` },
	{ title: `Kontakt`, path: `/kontakt` },
];

export default navLinks;
