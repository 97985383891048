// External libraries
import React, { ReactElement, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// UI Components
import { Box, Button, Typography } from '@material-ui/core';

// Style
import '../style/home-style.scss';

const HeaderComponent: React.FC = (): ReactElement => {
	const [width, setWidth] = useState(1000);

	useEffect(() => {
		onResize();
		window.addEventListener('resize', onResize);

		return () => {
			window.removeEventListener('resize', onResize);
		};
	}, []);

	function onResize() {
		setWidth(window.innerWidth);
	}

	const isMobilePhone = width < 800;
	const titleSize = isMobilePhone ? 'h4' : 'h3';
	const headingAlignRight = isMobilePhone ? 'center' : 'right';
	const textAlignRight = isMobilePhone ? 'justify' : 'right';
	const headingAlignLeft = isMobilePhone ? 'center' : 'left';
	const textAlignLeft = isMobilePhone ? 'justify' : 'left';

	return <>
		<div style={{backgroundImage: 'url("images/novo4.jpg")'}} className='main-image'></div>
		<div className='text-container'>
			<Typography variant={titleSize} gutterBottom={true} color='secondary' align={headingAlignRight}>
				Kope Bau – za vaše bolje sutra.
			</Typography>
			<Box m={4}/>
			<Typography variant='h6' color='textPrimary' align={textAlignRight}>
				Kope Bau prati dinamiku moderne svakodnevice.
				<br/>
				Ovaj objekat je koncipiran tako da odgovara svim generacijama i raznovrsnim životnim stilovima.
				<br/>
				Savremeni dizajn i visoka funkcionalnost, kao i prirodna osvetljenost stanova čine Kope Bau ponudu jedinstvenom u Pančevu.
			</Typography>
		</div>

		<div className='to-left-prim-sec'></div>

		<div className='location'>
			<img src='images/pancevo.jpg' alt='Slika dnevne sobe' className='location-image slide-left'/>
			<div className='loc-text'>
				<Box m={4}/>
				<Typography variant='h3' gutterBottom={true} color='primary' align={headingAlignRight}>
					Lokacija
				</Typography>
				<Box m={4}/>
				<Typography variant='h6' color='textPrimary' align={textAlignRight}>
					Kope Bau objekat nalazi se u neposrednoj okolini Beograda,
					<br/>
					ali daleko od gradske gužve, saobraćajnog kolapsa i bučne okoline.
					<Box m={4}/>
					Dovoljno daleko da Vam pruži potpuni mir,
					<br/>
					a dovoljno blizu da kratkom šetnjom stignete do centra.
				</Typography>
				<Box m={4}/>
				<Button variant='outlined' color='primary' className='location-btn' size='large'>
					<Link to='/lokacija'>
						Saznaj više
					</Link>
				</Button>
			</div>
		</div>

		<div className='to-right-sec-white'></div>

		<div className='content'>
			<div>
				<Typography variant='h3' gutterBottom={true} color='secondary' align={headingAlignLeft}>
					Stanovi
				</Typography>
				<Box m={4}/>
				<Typography variant='h6' color='primary' align={textAlignLeft}>
					Kope Bau objekat nudi 8 stanova, podeljenih na 4 sprata.
					<br/>
					Takođe, poseduje dva garažna parking mesta u prizemlju zgrade.
					<Box m={4}/>
					Građen je po najvišim arhitektonskim standardima uz korišćenje vrhunskih materijala,
					<br/>
					što će Vam pružiti potpuni ugođaj savremenog života.
				</Typography>
				<Box m={'15%'}/>
				<div style={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
					<Button variant='outlined' color='secondary' className='content-btn' size='large'>
						<Link to='/stanovi'>
							Stanovi
						</Link>
					</Button>
					<Button variant='outlined' color='secondary' className='content-btn' size='large'>
						<Link to='/sadrzaj'>
							Naša gradnja
						</Link>
					</Button>
				</div>
			</div>
			<img src='images/home-bedroom.jpeg' alt='Slika dnevne sobe' className='home-bedroom-image'/>
		</div>
	</>;
};

export default HeaderComponent;
