// External libraries
import React, { ReactElement, useEffect, useState } from 'react';

// UI Components
import { Box, List, ListItem, Typography } from '@material-ui/core';

// Style
import '../style/content-style.scss';

const characteristics = [
	'-Povećanje prirodne svetlosti zahvaljujući savremenom dizajnu proizvoda sa širokim otvorima',
	'-Apsolutna energetska efikasnost',
	'-Povećana sigurnosti',
	'-Visok stepen zvučne izolacije',
	'-Jednostavnost upotrebe i optimalna funkcionalnost',
	'-Vrhunski kvalitet sa sertifikovanim performansama'
];

const ContentComponent: React.FC = (): ReactElement => {
	const [width, setWidth] = useState(1000);

	useEffect(() => {
		onResize();
		window.addEventListener('resize', onResize);
	}, []);

	function onResize() {
		setWidth(window.innerWidth);
	}

	return <>
		<div className='content-container'>
			<Typography variant='h2' gutterBottom={true} color='primary' align='left'>
				Sadržaj
			</Typography>
			<Box m={width < 800 ? '20%' : '5%'}/>
			<Typography variant='h6' color='textPrimary' align={width < 800 ? 'justify' : 'left'}>
				<Typography variant='h3' gutterBottom={true} color='primary' align='left'>
					Toplotne pumpe
				</Typography>
				Svaki stan opremljen je najsavremenijim sistemom grejanja i hlađenja kroz individualne pumpe i fan coil radijatore,
				<br/>
				koji brzo i precizno postižu željenu temperaturu. Ova energetski efikasna rešenja, poznata po udobnosti i ekonomičnosti,
				<br/>
				predstavljaju jedan od najtraženijih sistema na tržištu.
				<Box m={8}/>
				<Typography variant='h3' gutterBottom={true} color='primary' align='left'>
					Podno grejanje
				</Typography>
				Svako kupatilo poseduje podno grejanje, a pored toga obezbeđen je i sušač za veš.
				<Box m={8}/>
				<Typography variant='h3' gutterBottom={true} color='primary' align='left'>
					Stolarija
				</Typography>
				Aluminijumska stolarija marke REHAU sedmokomorna sa trostrukim termoizolacionim staklom
				<br/>
				obezbeđuje savršenu toplotnu i zvučnu izolaciju za Vaš dom.
				<Box m={8}/>
				<Typography variant='h3' gutterBottom={true} color='primary' align='left'>
					Sigurnosna vrata
				</Typography>
				Dodatnu bezbednost obezbeđuju Dierre italijanska sigurnosna vrata, dok su unutrašnja vrata urađena od
				<br/>
				najkvalitetnijeg medijapana farbanog poliuretanom koji će ulepšati Vaš životni prostor.
				<Box m={8}/>
				<Typography variant='h3' gutterBottom={true} color='primary' align='left'>
					Hrastov parket
				</Typography>
				Hrastov parket prve klase donosi prirodan šarm i kvalitet, čineći prostor udobnijim i stilski uređenim.
				<Box m={8}/>
				<Typography variant='h3' gutterBottom={true} color='primary' align='left'>
					Lift
				</Typography>
				Za lagodno kretanje do vašeg stana obezbedili smo lift marke KLEEMMAN.
				<Box m={8}/>
				<Typography variant='h3' gutterBottom={true} color='primary' align='left'>
					Tehnika u objektu
				</Typography>
				Objekat je opremljen optičkim internetom (MTS, SBB), nadzornim kamerama koje možete pogledati
				<br/>
				sa svojih smart uređaja, interfonom...
				<Box m={8}/>
				<Typography variant='h3' gutterBottom={true} color='primary' align='left'>
					Parking i garaže
				</Typography>
				Zaštitite svoj automobil parkirajući ga na privatnom parkingu ili u garaži.
				<br/>
				Objekat poseduje 2 garažna mesta i 6 parking mesta.
				<Box m={8}/>
				<Typography variant='h3' gutterBottom={true} color='primary' align='left'>
					Video nadzor javnih površina
				</Typography>
				Nadzor parkinga, ulaza i dvorišta – za Vaš mir i sigurnost.
				<Box m={8}/>
				<Typography variant='h3' gutterBottom={true} color='primary' align='left'>
					Fasada
				</Typography>
				Na terasama se nalazi TRAVERTINO keramika sa najsavremenijom DEMIT fasadom.
				<Box m={8}/>
				<Typography variant='h3' gutterBottom={true} color='primary' align='left'>
					Zelena površina
				</Typography>
				Ograđeni odeljak za rekreaciju dece i ljubimaca na otvorenom.
			</Typography>
		</div>
		<div className='to-right-sec-white'></div>
		<div className='equipment-container'>
			<Typography variant='h3' gutterBottom={true} color='secondary' align='right' >
				Oprema našeg objekta
			</Typography>
			<Box m={width < 800 ? '20%' : '5%'}/>
			<Typography variant='h4' gutterBottom={true} color='primary' align='right' >
				Objekat poseduje energetski pasoš – Energetska B klasa.
			</Typography>
			<Box m={width < 800 ? '20%' : '5%'}/>
			<Typography variant='h6' color='primary' align={width < 800 ? 'justify' : 'right'}>
				<Typography variant='h3' gutterBottom={true} color='secondary' align='right'>
					Stolarija
				</Typography>
				Uživajte u prirodnom izvoru svetlosti uz prostrane staklene površine.
				<Box m={4}/>
				Aluminijumska stolarija marke REHAU sedmokomorna sa trostrukim termoizolacionim staklom
				<br/>
				obezbeđuje savršenu toplotnu i zvučnu izolaciju za Vaš dom.
				<Box m={4}/>
				<Typography variant='h4' gutterBottom={true} color='primary' align='right'>
					Karakteristike stolarije:
				</Typography>
				<Box m={2}/>
				<List dense={true}>
					{characteristics.map(c => {
						return (
							<ListItem key={c} style={{display: 'flex', justifyContent: 'flex-end', paddingRight: 0, fontStyle: 'italic'}}>
								<Typography variant='h5' color='primary' align='right'>
									{c}
								</Typography>
							</ListItem>
						);
					})}
				</List>
				<Box m={8}/>
				<Typography variant='h3' gutterBottom={true} color='secondary' align='right'>
					Struktura zidova
				</Typography>
				Zidovi su napravljeni KLIMABLOC linije premium proizvoda Zorka Opeke.
				<br/>
				Karakterišu je austrijski dizajn i tehnologija, veoma dobar kvalitet gline,
				<br/>
				odlična toplotna i zvučna izolacija.
				<Box m={8}/>
				<Typography variant='h3' gutterBottom={true} color='secondary' align='right'>
					Podovi
				</Typography>
				Ućinićemo Vaš enterijer toplim i održavanje doma jednostavnim.
				<br/>
				Ugrađeni pod od pravog drveta - parket, lakiran ekološkim lakom na bazi vode, otporan na ogrebotine.
				<br/>
				Budite slobodni da izaberete boju Vašeg poda od ponuđenih nijansi.
				<Box m={8}/>
				<Typography variant='h3' gutterBottom={true} color='secondary' align='right'>
					Lift
				</Typography>
				Za lagodno kretanje do vašeg stana tu je lift marke KLEEMMAN.
				<Box m={8}/>
			</Typography>
		</div>
	</>;
};

export default ContentComponent;
