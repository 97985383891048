/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/brace-style */
/* eslint-disable @typescript-eslint/semi */
/* eslint-disable arrow-body-style */
// External libraries
import React, { ReactElement, useEffect, useRef, useState } from 'react';

// UI Components
import { Box, Button, GridList, GridListTile, GridListTileBar, Typography } from '@material-ui/core';

// Style
import '../style/apartments-style.scss';
import { useHistory } from 'react-router';

const buildings = [
	{
		name: 'Milke Marković 21, Pančevo',
		image: 'images/building-front.jpg', // Dedicated image for the building selector
		floors: [
			{
				name: 'Podrum',
				apartments: [
					{
						number: 0,
						image: 'images/plan-podrum.png'
					}
				],
				img: 'basement.jpg'
			},
			{
				name: 'Prizemlje',
				apartments: [
					{
						number: 1,
						image: 'images/plan-prizemlje.png'
					}
				],
				img: 'ground.jpeg'
			},
			{
				name: 'Prvi sprat',
				apartments: [
					{
						number: 2,
						image: 'images/plan-prvi-sprat.png'
					}
				],
				img: 'first.jpeg'
			},
			{
				name: 'Drugi sprat',
				apartments: [
					{
						number: 3,
						image: 'images/plan-drugi-sprat.png'
					}
				],
				img: 'second.jpeg'
			},
			{
				name: 'Treći sprat sprat',
				apartments: [
					{
						number: 4,
						image: 'images/plan-treci-sprat.png'
					}
				],
				img: 'third.jpeg'
			},
			{
				name: 'Povučeni sprat',
				apartments: [
					{
						number: 5,
						image: 'images/plan-povuceni-sprat.png'
					}
				],
				img: 'withdrawn.jpeg'
			}
		]
	}
	// Add other buildings here
];

const ProjectsComponent: React.FC = (): ReactElement => {
	const [currentBuilding, setCurrentBuilding] = useState(-1);
	const [currentFloor, setCurrentFloor] = useState(-1);
	const [width, setWidth] = useState(1000);

	const history = useHistory();
	const backListener = useRef<any>(null);

	useEffect(() => {
		onResize();
		window.addEventListener('resize', onResize);
		backListener.current = history.listen(location => {
			if (location.pathname === '/projekti' && location.hash === '') {
				setCurrentBuilding(-1); // Reset to building selection
				setCurrentFloor(-1);
			}
		});

		return () => {
			backListener.current();
		};
	}, []);

	function onResize() {
		setWidth(window.innerWidth);
	}

	function chooseBuilding(buildingIndex: number) {
		setCurrentBuilding(buildingIndex);
		setCurrentFloor(-1);
		history.push(`#${buildingIndex}`);
	}

	function chooseFloor(floorIndex: number) {
		setCurrentFloor(floorIndex);
		history.push(`#${currentBuilding}-${floorIndex}`);
	}

	function isMobile() {
		return width < 800;
	}

	function backClick() {
		if (currentFloor >= 0) {
			// If on a floor, go back to building selection
			setCurrentFloor(-1);
			history.push(`#${currentBuilding}`);
		} else if (currentBuilding >= 0) {
			// If on a building, go back to the main building selection
			setCurrentBuilding(-1);
			history.push('/projekti'); // Reset to the base URL
		}
	}

	return (
		<>
			{currentBuilding < 0 ? (
				<div className='buildings-container' style={{ padding: '20px' }}>
					<GridList cols={isMobile() ? 1 : 2} cellHeight={isMobile() ? 300 : 360} spacing={isMobile() ? 50 : 100}>
						{buildings.map((building, i) => (
							<GridListTile key={i} classes={{ tile: 'center-image' }}>
								<img
									src={building.image}
									alt={building.name}
									className='grid-item'
									onClick={() => chooseBuilding(i)}
								/>
								<GridListTileBar
									title={
										<Typography variant='h4' color='secondary' align='right'>
											{building.name}
										</Typography>
									}
									style={{ background: 'rgba(0, 0, 0, 0.7)' }}
								/>
							</GridListTile>
						))}
					</GridList>
				</div>
			) : currentFloor < 0 ? (
				<div className='floors-container'>
					<GridList cols={isMobile() ? 1 : 2} cellHeight={isMobile() ? 300 : 360} spacing={isMobile() ? 50 : 100}>
						{buildings[currentBuilding].floors.map((floor, i) => (
							<GridListTile key={floor.img} classes={{ tile: 'center-image' }}>
								<img src={`images/${floor.img}`} alt={floor.name} className='grid-item' onClick={() => chooseFloor(i)} />
								<GridListTileBar
									title={
										<Typography variant='h4' color='secondary' align='right'>
											{floor.name}
										</Typography>
									}
									style={{ background: 'rgba(0, 0, 0, 0.7)' }}
								/>
							</GridListTile>
						))}
					</GridList>
				</div>
			) : (
				<div className='apartments-container'>
					<Typography variant='h2' gutterBottom={true} color='secondary' align='center'>
						{buildings[currentBuilding].floors[currentFloor].name}
					</Typography>
					<Box m={4} />
					<div className='apartments-box'>
						{buildings[currentBuilding].floors[currentFloor].apartments.map(apartment => (
							<div key={apartment.number} className='apartment'>
								<img src={apartment.image} alt={`Stan br. ${apartment.number + 1}`} className='plan-image' />
							</div>
						))}
					</div>
					<Button variant='contained' color='primary' className='apartments-btn' size='large' onClick={backClick}>
						Nazad na odabir sprata
					</Button>
				</div>
			)}
		</>
	);
};

export default ProjectsComponent;