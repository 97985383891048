/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/brace-style */
/* eslint-disable @typescript-eslint/semi */
/* eslint-disable arrow-body-style */
// External libraries
import React, { ReactElement, useEffect, useRef, useState } from 'react';

// UI Components
import { Box, Button, GridList, GridListTile, GridListTileBar, Typography } from '@material-ui/core';

// Style
import '../style/apartments-style.scss';
import { useHistory } from 'react-router';

const floors = [{
	name: 'Prizemlje',
	apartments: [
		{
			number: 0,
			image: 'images/PRIZEMLJE.jpg'
		}
	],
	img: 'garaza.jpg'
}, {
	name: 'Prvi sprat',
	apartments: [
		{
			number: 1,
			image: 'images/I-SPRAT.jpg'
		}
	],
	img: 'stan1.jpg'
}, {
	name: 'Drugi sprat',
	apartments: [
		{
			number: 2,
			image: 'images/II-SPRAT.jpg'
		}
	],
	img: 'stan2.jpg'
},{
	name: 'Treći sprat',
	apartments: [
		{
			number: 3,
			image: 'images/III-SPRAT.jpg'
		}
	],
	img: 'stan3.jpg'
},{
	name: 'Povučeni sprat',
	apartments: [
		{
			number: 4,
			image: 'images/POVUCENI-SPRAT.jpg'
		}
	],
	img: 'stan4.jpg'
},
// Add other floors here
];

const ApartmentsComponent: React.FC = (): ReactElement => {
	const [currentFloor, setCurrentFloor] = useState(-1);
	const [width, setWidth] = useState(1000);

	const history = useHistory();
	const backListener = useRef<any>(null);

	useEffect(() => {
		onResize();
		window.addEventListener('resize', onResize);
		backListener.current = history.listen(location => {
			if (location.pathname === '/stanovi' && location.hash === '') {
				setCurrentFloor(-1);
			}
		});

		return () => {
			backListener.current();
		};
	}, []);

	function onResize() {
		setWidth(window.innerWidth);
	}

	function chooseFloor(floorIndex: number) {
		setCurrentFloor(floorIndex);
		history.push(`#${floorIndex}`);
	}

	function isMobile() {
		return width < 800;
	}

	function backClick() {
		history.goBack();
	}

	return (
		<>
			{currentFloor < 0 ? (
				<div className='floors-container'>
					<GridList cols={isMobile() ? 1 : 2} cellHeight={isMobile() ? 300 : 360} spacing={isMobile() ? 50 : 100}>
						{floors.map((floor, i) => (
							<GridListTile key={floor.img} classes={{ tile: 'center-image' }}>
								<img src={`images/${floor.img}`} alt={floor.name} className='grid-item' onClick={() => chooseFloor(i)} />
								<GridListTileBar
									title={
										<Typography variant='h4' color='secondary' align='right'>
											{floor.name}
										</Typography>
									}
									style={{ background: 'rgba(0, 0, 0, 0.7)' }}
								/>
							</GridListTile>
						))}
					</GridList>
				</div>
			) : (
				<div className='apartments-container'>
					<Typography variant='h2' gutterBottom={true} color='secondary' align='center'>
						{floors[currentFloor].name}
					</Typography>
					<Box m={4} />
					<div className='apartments-box'>
						{floors[currentFloor].apartments.map(apartment => (
							<div key={apartment.number} className='apartment'>
								<img src={apartment.image} alt={`Stan br. ${apartment.number + 1}`} className='plan-image' />
							</div>
						))}
					</div>
					<Button variant='contained' color='primary' className='apartments-btn' size='large' onClick={backClick}>
						Nazad na odabir sprata
					</Button>
				</div>
			)}
		</>
	);
};

export default ApartmentsComponent;