// External libraries
import React, { useEffect, useState } from 'react';
import { Switch, Route, withRouter, RouteComponentProps, Redirect } from 'react-router-dom';

// UI components
import { CircularProgress } from '@material-ui/core';
import HeaderComponent from './header-component';
import HomeComponent from './home-component';
import FooterComponent from './footer-component';
import LocationComponent from './location-component';
import ApartmentsComponent from './apartments-component';
import ContentComponent from './content-component';
import ProjectsComponent from './projects-component';
import ContactComponent from './contact-component';

// Style
import '../style/app-style.scss';

function App({ history }: RouteComponentProps) {
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		const unlisten = history.listen(() => {
			window.scrollTo(0, 0);
		});

		setTimeout(() => {
			return setLoaded(true);
		}, 3000);

		return () => {
			unlisten();
		};
	}, []);

	return (
		<div className='app-container'>
			<HeaderComponent />
			<Switch key='router-switch'>
				<Route key='route-home' exact={true} path='/'>
					<HomeComponent />
				</Route>
				<Route key='route-location' exact={true} path='/lokacija'>
					<LocationComponent />
				</Route>
				<Route key='route-apartments' exact={true} path='/stanovi'>
					<ApartmentsComponent />
				</Route>
				<Route key='route-content' exact={true} path='/sadrzaj'>
					<ContentComponent />
				</Route>
				<Route key='route-content' exact={true} path='/projekti'>
					<ProjectsComponent />
				</Route>
				<Route key='route-contact' exact={true} path='/kontakt'>
					<ContactComponent />
				</Route>
				<Route path='*'>
					<Redirect to='/' />
				</Route>
			</Switch>
			<FooterComponent />
			<div className={`loading ${loaded && 'hide-loading'}`}>
				<CircularProgress color='secondary' style={{visibility: loaded ? 'hidden' : 'visible'}}/>
			</div>
		</div>
	);
}

export default withRouter(App);
